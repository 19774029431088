.popup-box {
  position: absolute;
  width: 90%;
  background: #211F4B;
  top: 70px;
  bottom: 70px;
  left: 0;
  right: 0;
  margin: auto;
  perspective: 1000px;
}
.close-popup-box {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  right: -20px;
  visibility: hidden;
  opacity: 0;
  font-size: 18px;
  line-height: 44px;
  color: #211F4B;
  text-align: center;
  background: #3D3887;
  border-radius: 50%;
  transition: all 180ms linear 10ms, color 180ms linear 10ms, visibility 200ms linear 700ms, opacity 200ms linear 700ms, transform 200ms linear 700ms;

  &:after {
    content: 'X';
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  &:hover {
    color: #3D3887;
    background: #fff;
    cursor: pointer;
  }
  &.clicked {
    animation: bubbling 150ms linear 10ms;
  }
}
@keyframes bubbling {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.85);
  }
  100% {
    transform: scale(1);
  }
}
.popup-box-logo {
  width: 140px;
  display: block;
  margin: 80px auto 0;
  visibility: hidden;
  opacity: 0;
  transform: scale(.95) rotateX(90deg);
  transition: all 300ms ease-out;

  &.yt {
    width: 90px;
  }
  &.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1) rotateX(0);
  }
}
.popup-box-inner {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.hero-title {
  display: inline-block;
  padding: 3px 0;
  position: relative;
  font-size: 16px;
  color: #9F9DD7;
  text-decoration: none;
  margin: 6px 0;
  transition: color 160ms linear 10ms;

  &:before,
  &:after {
    content: '';
    width: 0;
    height: 1px;
    position: absolute;
    top: 11px;
    background-color: rgba(159,157,215,.5);
    transition: width 180ms ease-out 10ms;
  }

  &:before {
    left: -44px;
  }
  &:after {
    right: -44px;
  }
  &:hover  {
    color: #6967C0;

    &:before,
    &:after {
      width: 30px;
    }
  }
  &.active {
    color: #6967C0;

    &:hover {
      color: #9F9DD7;
    }
  }
  &:active {
    &:before,
    &:after {
      width: 100%;
    }
  }
}

.hero-line {
  position: absolute;
  background: #1A1737;
  transition: all 600ms ease-out 10ms;

  &.top {
    width: 40%;
    width: 0;
    height: 16px;
    left: -8px;
    top: -8px;
  }
  &.top-left {
    width: 16px;
    height: 40%;
    height: 0;
    left: -8px;
    top: 0;
  }
  &.bottom {
    width: 0;
    height: 16px;
    right: -8px;
    bottom: -8px;
  }
  &.bottom-right {
    width: 16px;
    height: 0;
    right: -8px;
    bottom: 0;
  }

  &.active {
    &.top {
      width: 40%;
    }
    &.top-left {
      height: 40%;
    }
    &.bottom {
      width: 40%;
    }
    &.bottom-right {
      height: 40%;
    }
  }

}
@keyframes moving {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-body {
  position: relative;
  width: 42px;
  height: 42px;
  margin: 0 auto;
}
.loading-circle {
  width: 42px;
  height: 42px;
  border: 4px solid transparent;
  border-top-color: #6763BE;
  border-bottom-color: #6763BE;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  animation: moving 700ms linear infinite;

  &.b {
    width: 24px;
    height: 24px;
    animation-duration: 800ms;
  }
}

.fromBottom-enter {
  opacity: 0;
  visibility: hidden;
  transform: scale(.95);

  &.fromBottom-enter-active {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: all 200ms ease-out;
  }
}
.fromBottom-leave {
  opacity: 1;
  visibility: visible;

  &.fromBottom-leave-active {
    opacity: 0;
    visibility: hidden;
    transform: scale(.92);
    transition: all 200ms ease-out;
  }
}