@media screen and (max-width: 1024px) {
  .root {
    height: auto;
  }
  .page-content {
    width: 100%;
    margin: 0;
  }
  .hero-item-container {
    width: 100%;
  }
  .hero-item {
    display: block;
    margin: 60px auto !important;
  }
  .copyright {
    position: static;
    margin: 20px 0;
  }
  .wrapper {
    height: auto;
  }
  .logo {
    margin: 70px auto;
  }
  .popup-box {
    width: 80%;
  }
  .popup-box-inner {
    padding: 30px;
    box-sizing: border-box;
  }
  .popup-box-logo {
    display: none;
  }
  .hero-title {
    &:before,
    &:after {
      content: none;
    }
  }
  .main-content {
    margin-top: 0px;
  }
  .circle-body {
    display: block;
    margin: 0 auto 50px !important;

    // background-color: #f00;

  }
}