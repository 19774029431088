@import 'reset.css';
@import 'variables.scss';
@import 'fonts.scss';

body {
  font-family: 'Raleway';
}

.wrapper {
  height: 100vh;
  background-color: $portGore;
  overflow: hidden;
}

.logo {
  display: block;
  width: 220px;
  margin: 110px auto;
}

@import 'responsive.scss';