@import 'variables.scss';

.main-content {
  margin-top: 200px;
  text-align: center;

  .circle-body {
    &:nth-child(odd) {
      margin-top: 40px;
    }
  }
}
.circle-body {
  display: inline-block;
  width: 136px;
  margin: 0 13px;
  vertical-align: top;
  text-align: center;
  user-select: none;

  &:hover {
    cursor: pointer;

    .circle {
      box-shadow: 0 0  0 8px $gigas;
      background: #272558;
      animation: shadow 260ms linear 10ms;

      i {
        color: #2BB546;
      }
    }
  }
  &:active {
    .circle {
      box-shadow: 0 0  0 24px $gigas;

      i {
        color: #A09DD7;
      }
    }
  }
}

@keyframes shadow {
  0% {
    box-shadow: 0 0  0 0 $gigas;
  }
  50% {
    box-shadow: 0 0  0 16px $gigas;
  }
  100% {
    box-shadow: 0 0  0 8px $gigas;
   }
}

.circle {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border: 4px solid $white;
  text-align: center;
  border-radius: 50%;
  transition: all 200ms linear 10ms;

  i {
    font-size: 24px;
    line-height: 70px;
    color: #A09DD7;
    transition: color 160ms linear 10ms;
  }
}
.circle-logo {
  margin: 25px 0 0 0;

  &.codecanyon {
    width: 90px;
  }
  &.creativeMarket {
    width: 80px;
    margin-top: 22px;
  }
  &.android {
    width: 80px;
  }
  &.yt {
    width: 50px;
    margin-top: 23px;
  }
  &.github {
    width: 65px;
  }
}